<template>
    <div class="content-main">
        <div>
            <div class="content-header">
                <h2>勘误表</h2>
            </div>
            <div>
                <el-divider></el-divider>
            </div>
        </div>
        <div class="content-title">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>勘误表</el-breadcrumb-item>
                <el-breadcrumb-item>{{info.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="content-schema">
            <h3>{{info.title}}</h3>
            <el-divider></el-divider>
        </div>
        <div class="eratta-content">
            <div class="eratta-content-date">
                更新时间：{{info.update}}
            </div>
            <div>
                卡号：{{info.card_no}}
            </div>
            <div>
                卡名：{{info.card_name}}
            </div>
            <div class="m-center">
                <el-image :src="info.img" style="max-width: 50%"></el-image>
            </div>
            <div>
                <p style="color: red">▼ 修正内容</p>
                <div v-html="info.modify"></div>
            </div>
            <div>
                <p style="color: red">▼ 修正理由</p>
                <div v-html="info.modify_notice"></div>
            </div>
            <div v-show="info.card_change_show > 0">
                <p style="color: red" >▼ 关于卡片更换</p>
                <div v-html="info.card_change"></div>
            </div>
            <div>
                <p style="color: red">▼ 关于卡片使用</p>
                <div v-html="info.card_use"></div>
            </div>

        </div>
        <div class="eratta-back">
            <div class="eratta-back-result m-a-none">
                <router-link :to="{path:'/index/erattaList'}"><i class="el-icon-caret-right"></i>返回勘误表</router-link>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "info",
        data() {
            return {
                info: {}
            }
        },
        created() {
            this.info = this.$route.query.info;
        }

    }
</script>

<style scoped>
    .content-main{
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 20px 0;
    }
    .content-header{
        text-align: center;
    }
    .content-title{
        width: 98%;
        margin: 0 auto;
    }
    .content-schema{
        text-align: center;
    }
    .eratta-back{
        margin: 0 auto;
        padding: 20px 0;
    }
    .eratta-back-result > a{
        display: block;
        height: 58px;
        width: 200px;
        text-decoration: none;
        margin: 0 auto;
        border: 1px solid #404040;
        line-height: 58px;
        letter-spacing: 2px;
        font-size: 19px;
        font-weight: bold;
    }
    .eratta-content{
        width: 92%;
        margin: 0 auto;
        font-size: 16px;
        letter-spacing: 1px;
        padding: 10px;
    }
    .eratta-content >div{
        margin-bottom: 5px;
    }
    .eratta-content-date{
        text-align: center;
        color: #aeaeae;
        font-weight: bold;
        font-size: 18px;
    }
</style>
